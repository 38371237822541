<template>
	<div class="py-4 px-2" style="display:flex;padding: 20px;height: 100%;overflow-y: auto;">
		<div style="flex:1;">
			<div class="mytableinner">
				<div style="font-size: 26px;text-align: center;">
					中国人民政治协商会议宁夏委员会
				</div>
				<div class="blxtitle" style="font-size: 20px;text-align: center;">
					<el-input type="text" placeholder="请输入" v-model="formData.sshy" class="ttttile"
						style="width: 300px;font-size: 30px;text-align: center">
					</el-input>
				</div>
				<div style="width: 100%;display: flex;align-items: center;justify-content: space-between;margin-top: 20px;">
					<div style="font-size: 16px;">
						提案号(<el-input type="text" placeholder="请输入" v-model="formData.code" size="mini"
						style="width: 100px;font-size: 16px;text-align: center">
					</el-input>)
					</div>
					<div style="font-size: 16px;" @click="showgetdate = true">
						类别（<el-select v-model="formData.lei_id" placeholder="请选择" size="mini" style="width: 150px;">
							<el-option v-for="item in classifyData" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>）
					</div>
					<div style="font-size: 16px;position: relative;" @click="showdate=true">
						{{changdate(formData.rq)}}
						<el-date-picker v-model="formData.rq" type="date" placeholder="选择日期" value-format="timestamp"
							style="opacity: 0;position: absolute;left: 0;right: 0;">
						</el-date-picker>
					</div>
				</div>
				<div class="tablebox">
					<div class="line autoheight" style="border-bottom: none;">
						<div class="namebox" style="width: 15%;">
							题目
						</div>
						<div class="value" style="width: 85%;position: relative;padding: 0;">
							<el-input type="textarea" autosize placeholder="请输入" v-model="formData.name">
							</el-input>
						</div>
					</div>
					<div class="autoheight" style="border: none;height: auto;min-height: 20px;display: flex;">
						<div class="namebox autoheight erjititle"
							style="width: 32%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-bottom: none;border-right: none;">
							提案形式
						</div>
						<div class="namebox autoheight"
							style="width: 68%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-bottom: none;">
							<el-select v-model="formData.taxs" placeholder="请选择">
								<el-option v-for="(item,index) in tianxs" :key="item.index" :label="item" :value="item">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="line autoheight" style="border-bottom: none;">
						<div class="namebox" style="width: 15%;">
							委员提案
						</div>
						<div style="width: 85%;position: relative;padding: 0;">
							<div style="width: 100%;display: flex;border-bottom: 1px solid rgb(0,128,0);">
								<div class="namebox autoheight canlclefb erjititle"
									style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 30px;">
									提案者
								</div>
								<div class="value autoheight" style="width: 25%;padding: 0;min-height: 30px;">
									<el-input :disabled="!(formData.taxs=='个人')" type="textarea" autosize
										:placeholder="formData.taxs=='个人'?'请输入':''" v-model="formData.taz">
									</el-input>
								</div>
								<div class="namebox autoheight canlclefb erjititle"
									style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 30px;">
									工作单位及职务
								</div>
								<div class="value autoheight" style="width: 35%;padding: 0;min-height: 30px;">
									<el-input :disabled="!(formData.taxs=='个人')" type="textarea" autosize
										:placeholder="formData.taxs=='个人'?'请输入':''" v-model="formData.gzdwjzw">
									</el-input>
								</div>
							</div>
							<div style="width: 100%;display: flex;">
								<div class="namebox autoheight canlclefb erjititle"
									style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height:30px;">
									联系方式
								</div>
								<div style="width: 80%;display: flex;flex-direction: column;">
									<div style="width: 100%;display: flex;border-bottom: 1px solid rgb(0,128,0);">
										<div class="namebox autoheight canlclefb erjititle"
											style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 30px;">
											手机号码
										</div>
										<div class="value autoheight" style="width: 25%;padding: 0;min-height: 30px;">
											<el-input :disabled="!(formData.taxs=='个人')" type="textarea" autosize
												:placeholder="formData.taxs=='个人'?'请输入':''" v-model="formData.sjhm">
											</el-input>
										</div>
										<div class="namebox autoheight canlclefb erjititle"
											style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 30px;">
											电子邮箱
										</div>
										<div class="value autoheight" style="width: 35%;padding: 0;min-height: 30px;">
											<el-input :disabled="!(formData.taxs=='个人')" type="textarea" autosize
												:placeholder="formData.taxs=='个人'?'请输入':''" v-model="formData.dzyx">
											</el-input>
										</div>
									</div>
									<div style="display: flex;">
										<div class="namebox autoheight canlclefb erjititle"
											style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 30px;">
											通讯地址
										</div>
										<div class="value autoheight" style="width: 80%;padding: 0;min-height: 30px;">
											<el-input :disabled="!(formData.taxs=='个人')" type="textarea" autosize
												:placeholder="formData.taxs=='个人'?'请输入':''" v-model="formData.txdz">
											</el-input>
										</div>
									</div>
								</div>
							</div>
			
						</div>
					</div>
					<div class="line autoheight" style="border-bottom: none;;min-height: 20px;">
						<div class="namebox" style="width: 15%;">
							集体提案
						</div>
						<div style="width: 85%;position: relative;padding: 0;display: flex;">
							<div class="canlclefb"
								style="width: 40%;display: flex;border-left: 1px solid rgb(0,128,0);font-size: 12px;flex-direction: column;">
								<div class="erjititle">
									提案单位(加盖公章)
								</div>
								<div v-if="formData.taxs=='集体'"
									style="width: 100%;display: flex;align-items: center;justify-content: center;margin-top: 5px;">
									<el-upload class="avatar-uploader" :action="baseurl+'/api/login/upload'"
										:show-file-list="false" :on-success="handleAvatarSuccess"
										:before-upload="beforeAvatarUpload">
										<img v-if="gzurl" :src="gzurl" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon"></i>
									</el-upload>
								</div>
			
							</div>
							<div class="autoheight" style="width: 60%;display: flex;flex-direction: column;border: none;">
								<div class="autoheight" style="width: 100%;display: flex;border: none;;min-height: 60px;">
									<div class="namebox autoheight canlclefb erjititle"
										style="width: 30%;border-left: 1px solid rgb(0,128,0);min-height: 60px;">
										负责人(签名)
									</div>
									<div class="value autoheight" style="width: 70%;padding: 0;min-height: 60px;">
										<el-input :disabled="!(formData.taxs=='集体')" type="textarea" autosize
											:placeholder="formData.taxs=='集体'?'请输入':''" v-model="formData.fzrqm">
										</el-input>
									</div>
								</div>
								<div class="autoheight"
									style="width: 100%;display: flex;border: none;;min-height: 60px;border-top: 1px solid rgb(0,128,0);">
									<div class="namebox autoheight canlclefb erjititle"
										style="width: 30%;border-left: 1px solid rgb(0,128,0);min-height: 60px;">
										单位及职务
									</div>
									<div class="value autoheight" style="width: 70%;padding: 0;min-height: 60px;">
										<el-input :disabled="!(formData.taxs=='集体')" type="textarea" autosize
											:placeholder="formData.taxs=='集体'?'请输入':''" v-model="formData.dwjzw">
										</el-input>
									</div>
								</div>
								<div class="autoheight"
									style="width: 100%;display: flex;border: none;;min-height: 60px;border-top: 1px solid rgb(0,128,0);">
									<div class="namebox autoheight canlclefb erjititle"
										style="width: 30%;border-left: 1px solid rgb(0,128,0);min-height: 60px;">
										联系电话
									</div>
									<div class="value autoheight" style="width: 70%;padding: 0;min-height: 60px;">
										<el-input :disabled="!(formData.taxs=='集体')" type="textarea" autosize
											:placeholder="formData.taxs=='集体'?'请输入':''" v-model="formData.lxdh">
										</el-input>
									</div>
								</div>
							</div>
			
						</div>
					</div>
					<div class="line autoheight" style="border-bottom: none;">
						<div class="namebox" style="width: 15%;">
							审查意见
						</div>
						<div class="value"
							style="justify-content: space-between;align-items: center;text-align: left;flex-direction: column;width: 85%;min-height: 180px;padding: 0;">
							<div style="width: 100%;display: flex;align-items: center;min-height: 180px;">							
								<div style="width: 50%;min-height: 180px;padding: 10px;border-right: 1px solid rgb(0,128,0);">
									
								</div>
								<div style="padding: 10px;width: 50%;min-height: 180px;display: flex;align-items: center;justify-content: space-between;flex-direction: column;">
									<div></div>
									<div style="width: 100%;text-align: right;">
										202 年 月 日
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="line autoheight" style="border-bottom: none;">
						<div class="namebox" style="width: 15%;">
							办理单位
						</div>
						<div class="autoheight" style="width: 85%;display: flex;border: none;min-height: 180px;">
							<div class="value autoheight"
								style="justify-content: flex-start;align-items: flex-start;text-align: left;width: 40%;flex-direction: column;">
								<div class="erjititle" style="font-size: 12px;width: 100%;">
									(主办单位)
								</div>
								<div>
									<span>已选：<span v-for="item in zbdw_name">{{item}};</span></span>
								</div>
			
								<el-button @click="selectdw(1)" size="small" type="primary">选择单位</el-button>
			
							</div>
							<div class="value autoheight"
								style="justify-content: flex-start;align-items: flex-start;text-align: left;width: 60%;flex-direction: column;">
								<div class="erjititle erjititle" style="font-size: 12px;width: 100%;">
									(协办单位)
								</div>
								<div>
									<span>已选：<span v-for="item in xbdw_name">{{item}};</span></span>
								</div>
								<el-button @click="selectdw(2)" size="small" type="primary">选择单位</el-button>
							</div>
						</div>
					</div>
					<div class="line" style="height: auto;">
						<div class="namebox" style="width: 100%;">
							联名提案栏
						</div>
					</div>
					<div class="line" style="border-bottom: none;height: auto;">
						<div class="namebox erjititle" style="width: 30%;font-weight: 400;font-size: 12px;">
							联名人(签名)
						</div>
						<div class="namebox erjititle" style="width: 40%;font-weight: 400;font-size: 12px;">
							工作单位及职务
						</div>
						<div class="namebox erjititle" style="width: 30%;font-weight: 400;font-size: 12px;">
							联系电话
						</div>
					</div>
			
					<div v-for="(item,index) in user_id" class="autoheight"
						style="border: none;height: auto;min-height: 20px;display: flex;">
						<div class="namebox autoheight"
							style="width: 30%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-right: none;border-bottom: none;">
							<div class="">
								{{item.name}}
							</div>
							<el-button type="danger" @click="user_id.splice(index, 1)" size="mini">删除</el-button>
			
						</div>
						<div class="namebox autoheight"
							style="width: 40%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-right: none;border-bottom: none;">
			
						</div>
						<div class="namebox autoheight"
							style="width: 30%;font-weight: 400;min-height: 20px;border: 1px solid rgb(0,128,0);border-bottom: none;">
							{{item.phone}}
						</div>
					</div>
					<div class="autoheight" style="border: none;height: auto;min-height: 20px;display: flex;">
						<div class="namebox autoheight"
							style="width: 30%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-right: none;">
							<el-button v-if="formData.taxs!='集体'" size="small" type="primary"
								@click="selectry">选择</el-button>
						</div>
						<div class="namebox autoheight"
							style="width: 40%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-right: none;">
			
						</div>
						<div class="namebox autoheight"
							style="width: 30%;font-weight: 400;min-height: 20px;border: 1px solid rgb(0,128,0)">
			
						</div>
					</div>
				</div>
				<div style="color: rgb(0,128,0);font-size: 12px;">
					注意事项：① 委员个人提案和联名提案第一提案者须在“委员提案”栏中填写信息；② 集体提案是指民主党派、工商联、人民团体、政协专门委员会和政协全体会议期间的界别、委员小组提出的提案；③
					委员联名提出提案，发起人作为第一提案者，其他联名人须在“联名提案栏”签名；④
					提案内容务必一事一案，字数控制在2000字以内，力求做到“切口小、道理硬、靶向准、建议实”；
				</div>
			
			
			</div>
			<div style="width: 100%;">
				<el-form :model="formData" :rules="formRules" label-width="1.3rem" ref="form"
					style="padding-bottom: 130px;">
				
					<el-form-item label="案由分析" required prop="content">
						<el-input type="textarea" :rows="8" v-model="formData.content" />
					</el-form-item>
				
					<el-form-item label="建议办法" required prop="way">
						<el-input type="textarea" :rows="8" v-model="formData.way" />
					</el-form-item>
				
				
					<!-- <el-form-item label="上传图片">
						<el-upload :action="baseurl+'/api/login/upload'" list-type="picture-card"
							:on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-success='uploadsuccess'
							:file-list="imaglist">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</el-form-item> -->
				
					<el-form-item label="上传附件">
						<el-upload class="upload-demo" :action="baseurl+'/api/login/upload'" multiple
							:on-success='uploadsuccessfujian' :file-list="fileList" :show-file-list='false'>
							<el-button size="small" type="primary">点击上传</el-button>
						</el-upload>
						<div v-for="(item,index) in fileList" :key="index"
							style="display: flex;align-items: center;justify-content: space-between;padding-right: 20%;width: 50%;">
							<div class="filenamebox" @click="goopen(item.url)"
								style="display: flex;align-items:center;cursor: pointer;">
								<i class="el-icon-circle-check"
									style="color: rgb(127,204,89);margin-right: 0.5rem;font-size: 1.2rem;">
								</i>
								{{item.file_name?item.file_name:item.name}}
							</div>
							<div style="cursor: pointer;" @click="removelist(index)"><i class="el-icon-close"></i></div>
						</div>
					</el-form-item>
				
				
					<div class="mt-16 pl-16" style="padding: 0 20px;">
						<el-button type="info" @click="onCancel"> 取消 </el-button>
						<el-button type="warning" @click="onSubmit(1)"> 保存 </el-button>
						<el-button type="primary" @click="onSubmit(2)"> 提交 </el-button>
					</div>
				</el-form>
			</div>
			
			
		</div>
		<div style="flex:1;overflow:auto;height:800px;position: relative;font-size: 0.16rem;">
			<div class="a4 ql-editor texta4" ref="print" style="padding: 20px 0.5rem;height: auto;"
				v-for="(item,index) in formDatalist" :key="index">
				<div class="mytableinner">
					<div style="font-size: 26px;text-align: center;">
						中国人民政治协商会议宁夏委员会
					</div>
					<div class="blxtitle" style="font-size: 20px;text-align: center;">
						{{item.sshy}}
					</div>
					<div style="width: 100%;display: flex;align-items: center;justify-content: space-between;margin-top: 20px;">
						<div style="font-size: 16px;">
							提案号({{item.code?item.code:''}})
						</div>
						<div style="font-size: 16px;" @click="showgetdate = true">
							类别（{{item.lei_name}}）
						</div>
						<div style="font-size: 16px;position: relative;" @click="showdate=true">
							{{item.rq}}
						</div>
					</div>
					<div class="tablebox">
						<div class="line autoheight" style="border-bottom: none;">
							<div class="namebox" style="width: 15%;">
								题目
							</div>
							<div class="value" style="width: 85%;position: relative;padding: 0;">
								{{item.name}}
							</div>
						</div>
						<div class="autoheight" style="border: none;height: auto;min-height: 20px;display: flex;">
							<div class="namebox autoheight erjititle"
								style="width: 32%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-bottom: none;border-right: none;">
								提案形式
							</div>
							<div class="namebox autoheight"
								style="width: 68%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-bottom: none;">
								{{item.taxs}}
							</div>
						</div>
						<div class="line autoheight" style="border-bottom: none;">
							<div class="namebox" style="width: 15%;">
								委员提案
							</div>
							<div style="width: 85%;position: relative;padding: 0;">
								<div style="width: 100%;display: flex;border-bottom: 1px solid rgb(0,128,0);">
									<div class="namebox autoheight canlclefb erjititle"
										style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 30px;">
										提案者
									</div>
									<div class="value autoheight" style="width: 25%;padding: 0;min-height: 30px;">
										{{item.taz}}
									</div>
									<div class="namebox autoheight canlclefb erjititle"
										style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 30px;">
										工作单位及职务
									</div>
									<div class="value autoheight" style="width: 35%;padding: 0;min-height: 30px;">
										{{item.gzdwjzw}}
									</div>
								</div>
								<div style="width: 100%;display: flex;">
									<div class="namebox autoheight canlclefb erjititle"
										style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height:30px;">
										联系方式
									</div>
									<div style="width: 80%;display: flex;flex-direction: column;">
										<div style="width: 100%;display: flex;border-bottom: 1px solid rgb(0,128,0);">
											<div class="namebox autoheight canlclefb erjititle"
												style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 30px;">
												手机号码
											</div>
											<div class="value autoheight" style="width: 25%;padding: 0;min-height: 30px;word-break: break-all;">
												{{item.sjhm}}
											</div>
											<div class="namebox autoheight canlclefb erjititle"
												style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 30px;">
												电子邮箱
											</div>
											<div class="value autoheight" style="width: 35%;padding: 0;min-height: 30px;word-break: break-all;">
												{{item.dzyx}}
											</div>
										</div>
										<div style="display: flex;">
											<div class="namebox autoheight canlclefb erjititle"
												style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 30px;">
												通讯地址
											</div>
											<div class="value autoheight" style="width: 80%;padding: 0;min-height: 30px;word-break: break-all;">
												{{item.txdz}}
											</div>
										</div>
									</div>
								</div>
					
							</div>
						</div>
						<div class="line autoheight" style="border-bottom: none;;min-height: 20px;">
							<div class="namebox" style="width: 15%;">
								集体提案
							</div>
							<div style="width: 85%;position: relative;padding: 0;display: flex;">
								<div class="canlclefb"
									style="width: 40%;display: flex;border-left: 1px solid rgb(0,128,0);font-size: 12px;flex-direction: column;">
									<div class="erjititle">
										提案单位(加盖公章)
									</div>
									<div v-if="item.gz[0]" style="width: 100%;display: flex;align-items: center;justify-content: center;margin-top: 10px;">
										<img :src="item.gz[0].fullurl" style="width: 70%;"></img>
									</div>
					
								</div>
								<div class="autoheight" style="width: 60%;display: flex;flex-direction: column;border: none;">
									<div class="autoheight" style="width: 100%;display: flex;border: none;;min-height: 60px;">
										<div class="namebox autoheight canlclefb erjititle"
											style="width: 30%;border-left: 1px solid rgb(0,128,0);min-height: 60px;">
											负责人(签名)
										</div>
										<div class="value autoheight" style="width: 70%;padding: 0;min-height: 60px;word-break: break-all;">
											{{item.fzrqm}}
										</div>
									</div>
									<div class="autoheight"
										style="width: 100%;display: flex;border: none;;min-height: 60px;border-top: 1px solid rgb(0,128,0);">
										<div class="namebox autoheight canlclefb erjititle"
											style="width: 30%;border-left: 1px solid rgb(0,128,0);min-height: 60px;">
											单位及职务
										</div>
										<div class="value autoheight" style="width: 70%;padding: 0;min-height: 60px;word-break: break-all;">
											{{item.dwjzw}}
										</div>
									</div>
									<div class="autoheight"
										style="width: 100%;display: flex;border: none;;min-height: 60px;border-top: 1px solid rgb(0,128,0);">
										<div class="namebox autoheight canlclefb erjititle"
											style="width: 30%;border-left: 1px solid rgb(0,128,0);min-height: 60px;">
											联系电话
										</div>
										<div class="value autoheight" style="width: 70%;padding: 0;min-height: 60px;word-break: break-all;">
											{{item.lxdh}}
										</div>
									</div>
								</div>
					
							</div>
						</div>
						<div class="line autoheight" style="border-bottom: none;">
							<div class="namebox" style="width: 15%;">
								审查意见
							</div>
							<div class="value"
								style="justify-content: space-between;align-items: center;text-align: left;flex-direction: column;width: 85%;min-height: 180px;padding: 0;">
								
								<div style="width: 100%;display: flex;align-items: center;min-height: 180px;">
									
									<div style="width: 50%;min-height: 180px;padding: 10px;border-right: 1px solid rgb(0,128,0);">
										{{item.scyj}}
									</div>
									<div style="padding: 10px;width: 50%;min-height: 180px;display: flex;align-items: center;justify-content: space-between;flex-direction: column;">
										<img v-if="item.shqm[0]" :src="item.shqm[0].fullurl" style="width: 70%;"></img>
										<div style="width: 100%;text-align: right;">
											{{item.scyjrq}}
										</div>
									</div>
								</div>
								
							</div>
						</div>
						<div class="line autoheight" style="border-bottom: none;">
							<div class="namebox" style="width: 15%;">
								办理单位
							</div>
							<div class="autoheight" style="width: 85%;display: flex;border: none;min-height: 180px;">
								<div class="value autoheight"
									style="justify-content: flex-start;align-items: flex-start;text-align: left;width: 40%;flex-direction: column;">
									<div class="erjititle" style="font-size: 12px;width: 100%;">
										(主办单位)
									</div>
									<div>
										{{item.blusers_name}}
									</div>
					
								</div>
								<div class="value autoheight"
									style="justify-content: flex-start;align-items: flex-start;text-align: left;width: 60%;flex-direction: column;">
									<div class="erjititle erjititle" style="font-size: 12px;width: 100%;">
										(协办单位)
									</div>
									<div>
										{{item.fbusers_name}}
									</div>
								</div>
							</div>
						</div>
						<div class="line" style="height: auto;">
							<div class="namebox" style="width: 100%;">
								联名提案栏
							</div>
						</div>
						<div class="line"  :style="{borderBottom: item.lmusers_name.length>0?'none':'1px solid rgb(0,128,0)',height: 'auto'}">
							<div class="namebox erjititle" style="width: 30%;font-weight: 400;font-size: 12px;">
								联名人(签名)
							</div>
							<div class="namebox erjititle" style="width: 40%;font-weight: 400;font-size: 12px;">
								工作单位及职务
							</div>
							<div class="namebox erjititle" style="width: 30%;font-weight: 400;font-size: 12px;">
								联系电话
							</div>
						</div>
					
						<div v-for="(item,index) in item.lmusers_name" class="autoheight lmtlist"
							style="border: none;height: auto;min-height: 20px;display: flex;">
							<div class="namebox autoheight"
								style="width: 30%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-right: none;border-bottom: none;">
								<div class="">
									{{item.name}}
								</div>
							</div>
							<div class="namebox autoheight"
								style="width: 40%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-right: none;border-bottom: none;">
					
							</div>
							<div class="namebox autoheight"
								style="width: 30%;font-weight: 400;min-height: 20px;border: 1px solid rgb(0,128,0);border-bottom: none;">
								{{item.phone}}
							</div>
						</div>
					</div>
					<div style="color: rgb(0,128,0);font-size: 12px;">
						注意事项：① 委员个人提案和联名提案第一提案者须在“委员提案”栏中填写信息；② 集体提案是指民主党派、工商联、人民团体、政协专门委员会和政协全体会议期间的界别、委员小组提出的提案；③委员联名提出提案，发起人作为第一提案者，其他联名人须在“联名提案栏”签名；④提案内容务必一事一案，字数控制在2000字以内，力求做到“切口小、道理硬、靶向准、建议实”；
					</div>
				</div>
				
				
				
				<div style="width:100%;margin-top: 80px;">提案号：{{item.id}}</div>
				<div style="font-size: 30px;text-align: center;margin-top: 5px;">关于{{item.name}}的提案</div>
				<div style="font-size:18px;text-align: center;margin-top:10px;">提案者：{{item.taz?item.taz:item.fzrqm}}</div>
				
				<div class="zwfontsize" style="font-family: fangsong;margin-top: 10px;margin-top:50px;">
					<span style="font-weight: bold;">案由分析：</span>
					<span class="myhtml" v-html="item.content"></span>
				</div>
				
				<div class="zwfontsize" style="font-family: fangsong;margin-top: 50px;">
					<span style="font-weight: bold;">建议办法：</span>
					<span class="myhtml" v-html="item.way"></span>
				</div>			
				<!-- 
				<div style="margin-top: 60px;display: flex;">
					<span>图片相关：</span>
					<div style="display: flex;align-items: center;flex-wrap: wrap;flex-direction: column;">
							<div class="box_img" v-for="(item,index) in formData.img" :key="index" style="margin: 10px;">
								<img :src="item.fullurl" alt="" style="width: 150px;">					
							</div>  
					</div>
				</div>
				 -->
				<div style="margin-top: 60px;display: flex;">
					<span style="color: #888;">附件列表：</span>
					<div style="display: flex;align-items: center;flex-wrap: wrap;flex-direction: column;width: 80%;">
							<div class="box_img" v-for="(item,index) in item.fujian" :key="index" style="margin: 10px;display: flex;align-items: center;justify-content: space-between;width: 100%;">
								<span style="color: #888;font-size: 14px;">{{item.filename}}</span>
								<el-button type="success" size="mini" @click="open(item.fullurl)"> 下载查看附件 </el-button>
							</div>  
					</div>
				</div>
			</div>
		</div>

		<selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>
		<selectcbdw ref="myselectcbdw" @selectedry="selecteddw"></selectcbdw>
	</div>
</template>
<script>
	import selectuser from '@/components/select_user.vue'
	import selectcbdw from '@/components/select_cbdw.vue'
	export default {
		components: {
			selectuser,
			selectcbdw
		},
		data() {
			return {
				baseurl: '',
				formDatalist: [], //其他提案
				formData: {
					name: null,
					lei_id: null,
					taxs: null,
					mingcheng: null,
					content: null,
					way: null,

					issue: null,
					proposal: null,
					classify_id: null,
					smallimages: null,
					attachfiles: null,
					joint_proposal_user_ids: null,
					admin_id: null
				},
				formRules: {
					name: [{
						required: true,
						message: "标题不能为空",
						whitespace: true
					}],
					mingcheng: [{
						required: true,
						message: "组织名称不能为空",
						whitespace: true
					}],
					content: [{
						required: true,
						message: "案由分析不能为空",
						whitespace: true
					}],
					way: [{
						required: true,
						message: "建议办法不能为空",
						whitespace: true
					}],
					issue: [{
						required: true,
						message: "案由分析不能为空",
						whitespace: true
					}],
					proposal: [{
						required: true,
						message: "建议办法不能为空",
						whitespace: true
					}],
				},
				classifyData: [], //提案类型
				tianxs: ['个人', '集体'],
				dialogVisible: false,
				dialogImageUrl: '',
				imaglist: [], //图片上传文件
				gzurl: "", //公章
				gz_id: '', //公章id
				qianziid: '', //签名id
				qianziurl: '', //签名url
				fileList: [], //附件列表
				user_id: [], //联名人
				user_name: "",
				
				zbdw_id: [], //主办单位
				zbdw_name: [],
				cbdwflag: 0, //1主办 2协办
				xbdw_id: [], //协办办单位
				xbdw_name: [],
				
				userlist: '', //用户列表
				loading: false,
				otherids:[],//其他的提案
				allids:'',
				qmurl:''
			};
		},
		created() {
			this.baseurl = this.$URL
			//获取提案类型
			this.$post({
				url: '/api/lei/index',
				params: {
					p: 1,
					size: 9999
				}
			}).then((res) => {
				this.classifyData = res.list
			})
			
			this.setdata()
			
			//获取所有的合并提案
			this.$post({
				url: '/api/proposa/xuanindex',
				params: {
			
				}
			}).then((res) => {
				this.allids = res.list.map((item)=>{ return item.id })
				this.otherids = this.allids.filter(item => Number(item) !== Number(this.$route.query.id));
				
				this.otherids.forEach((item)=>{
					this.getdetail(item)
				})
			})
			
		},
		methods: {
			
			onSubmit(){
				this.formData.zid = this.$route.query.id
				this.formData.ids = this.allids.join(',')
				delete this.formData.id				
				
				if (!this.formData.lei_id) {
					this.$message.warning('请选择提案类别')
					return false
				}
				
				if (!this.formData.taxs) {
					this.$message.warning('请选择提案形式')
					return false
				}
				if (this.formData.taxs == '集体' && !this.gz_id) {
					this.$message.warning('请上传公章')
					return false
				}
				
				
				if (this.imaglist.length > 0) {
					this.formData.img = this.imaglist.map((item) => {
						return item.id
					})
					this.formData.img = this.formData.img.join(',')
				}
				
				if (this.fileList.length > 0) {
					this.formData.fujian = this.fileList.map((item) => {
						return item.id
					})
					this.formData.fujian = this.formData.fujian.join(',')
				}
				
				this.formData.blusers = this.zbdw_id.join(',') //主办
				this.formData.blusers_name = this.zbdw_name.join(',')
				this.formData.fbusers = this.xbdw_id.join(',') //协办
				this.formData.fbusers_name = this.xbdw_name.join(',')
				
				if(typeof(this.formData.rq)=='number'){
					const currentDate = new Date(this.formData.rq)					
					const year = currentDate.getFullYear();
					const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 使用 padStart 方法补0
					const day = String(currentDate.getDate()).padStart(2, '0'); // 使用 padStart 方法补0
					time = year+'年'+month+'月'+day+'日'
				}
				
				this.formData.gz = this.gz_id ? this.gz_id : ''
				if (this.user_id.length > 0) {
					this.formData.lmusers = this.user_id.map((item) => {
						return item.id
					}).join(',')
				} else {
					this.formData.lmusers = ''
				}
				
				this.$post({
					url: '/api/proposa/tahb',
					params: this.formData
				}).then((res) => {
					this.$message.success('操作成功')
					this.$router.push('/system/tian_list')
				})
			},
			selecteddw(e) {
				// cbdwflag=1 主办 ==2协办
				if (this.cbdwflag == 1) {
					this.zbdw_id = e.map((item) => {
						return item.id
					})
					this.zbdw_name = e.map((item) => {
						return item.name
					})
				}
				if (this.cbdwflag == 2) {
					this.xbdw_id = e.map((item) => {
						return item.id
					})
					this.xbdw_name = e.map((item) => {
						return item.name
					})
				}
			},
			selectdw(iii) {
				this.cbdwflag = iii
				// iii=1 主办 =2协办
				let cxidlist = ''
				let cxnamelist = ''
				if (iii == 1) {
					cxidlist = JSON.parse(JSON.stringify(this.zbdw_id))
					cxnamelist = JSON.parse(JSON.stringify(this.zbdw_name))
					this.$refs.myselectcbdw.isdanxuan = true
				}
				if (iii == 2) {
					cxidlist = JSON.parse(JSON.stringify(this.xbdw_id))
					cxnamelist = JSON.parse(JSON.stringify(this.xbdw_name))
					this.$refs.myselectcbdw.isdanxuan = false
				}
				let list = []
				if (cxnamelist.length > 0) {
					cxidlist.forEach((item, index) => {
						let obj = {
							name: cxnamelist[index],
							id: Number(item)
						}
						list.push(obj)
					})
				}
				this.$refs.myselectcbdw.selectuser = list
				this.$refs.myselectcbdw.dialogVisible = true
			
			},
			changdate(e) {
				console.log(e)
				let time = e
				if(typeof(e)=='number'){
					const currentDate = new Date(this.formData.rq)					
					const year = currentDate.getFullYear();
					const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 使用 padStart 方法补0
					const day = String(currentDate.getDate()).padStart(2, '0'); // 使用 padStart 方法补0
					time = year+'年'+month+'月'+day+'日'
				}
				if(!e){
					const currentDate = new Date()					
					const year = currentDate.getFullYear();
					const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 使用 padStart 方法补0
					const day = String(currentDate.getDate()).padStart(2, '0'); // 使用 padStart 方法补0
					time = year+'年'+month+'月'+day+'日'
				}
				return time
			},
			onCancel(){
				this.$router.push('/system/tian_list')
			},
			getdetail(item){//获取其他提案的列表详情
				this.$post({
					url: '/api/proposa/details',
					params: {
						id:item
					}
				}).then((res) => {
					this.formDatalist.push(res)
				})
			},
			selectedry(e){
				this.user_id = e
			},
			goopen(url){
				window.open(url, '_blank');
			},
			setdata(){
				this.$post({
					url: '/api/proposa/details',
					params: {
						id:this.$route.query.id
					}
				}).then((res) => {
					this.gzurl = res.gz[0] ? res.gz[0].fullurl : ''
					this.gz_id = res.gz[0] ? res.gz[0].id : ''
					
					this.qianziurl = res.zzqm[0] ? res.zzqm[0].fullurl : res.zjrqm[0] ? res.zjrqm[0].fullurl : ''
					this.qianziid = res.zzqm[0] ? res.zzqm[0].id : res.zjrqm[0] ? res.zjrqm[0].id : ''
					
					
					this.zbdw_id = res.blusers?res.blusers.split(','):[]
					this.zbdw_name = res.blusers_name?res.blusers_name.split(','):[]
					this.xbdw_id = res.fbusers?res.fbusers.split(','):[]
					this.xbdw_name = res.fbusers_name?res.fbusers_name.split(','):[]
					
					this.formData = res
					if(res.img.length>0){
						res.img.forEach((item,index)=>{
							item.url = item.fullurl
						})
						this.imaglist = res.img
					}
					if(res.fujian.length>0){
						res.fujian.forEach((item,index)=>{
							item.url = item.fullurl
							item.file_name = item.filename
						})
						this.fileList = res.fujian
					}
					if(res.lmusers_name.length>0){
						this.user_id = res.lmusers_name.map((item)=>{return item.id})
						this.user_name = res.lmusers_name.map((item)=>{return item.name})
					}
					res.user_name.forEach((item,index)=>{
						this.remoteMethod(item.name)
					})
					this.zhuanren = res.user_name.map((item)=>{return item.id})
				})
			},
			remoteMethod(query) {
				if (query !== '') {
					this.loading = true;					
					//获取提案类型
					this.$post({
						url: '/api/user/index',
						params: {
							name:query,
							p: 1,
							size: 9999
						}
					}).then((res) => {
						this.loading = false;
						this.userlist = res.list
					})
				} else {
					this.userlist = [];
				}
			},

			selectry() {
				this.$refs.myselectuser.selectuser = this.user_id
				this.$refs.myselectuser.dialogVisible = true
			},


			//附件上传相关--------------
			removelist(index) {
				this.fileList.splice(index, 1)
			},
			uploadsuccessfujian(response, file, fileList) {
				this.fileList.push(response)
			},
			//附件上传结束-------------------------


			//图片上传相关-------------
			uploadsuccess(response, file, fileList) {
				response.url = response.fullurl
				this.imaglist.push(response)
			},
			handleRemove(file, fileList) {
				this.imaglist = []
				fileList.forEach((item, index) => {
					this.imaglist.push(item.response ? item.response.data : item)
				})
				console.log(this.imaglist)
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;

			},
			//图片上传结束--------------------


			//公章上传--------------------------------
			handleAvatarSuccess(res) {
				this.gzurl = res.fullurl
				this.gz_id = res.id
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG或PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			}
			//公章上传--结束------------------------------

		},

	};
</script>

<style lang="scss" scoped>
	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #999;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 145px;
		height: 145px;
		line-height: 145px;
		text-align: center;
	}

	.avatar {
		width: 145px;
		height: 145px;
		display: block;
	}

	::v-deep .el-icon-circle-check:before {
		font-size: 0.2rem;
	}

	::v-deep .el-icon-circle-check {
		font-size: 0.2rem !important;
		line-height: 0.16rem;
		height: 0.16rem;
	}

	::v-deep .el-dialog__header {
		display: flex;
	}
	.filenamebox:hover{
		color: #409EFF;
	}
	.line {
		display: flex;
		align-items: center;
		height: 50px;
		width: 100%;
	}
	
	.namebox {
		height: 100%;
		width: 12%;
		border: 1px solid rgb(0, 128, 0);
		padding: 5px 2px;
		text-align: center;
		border-right: none;
		border-bottom: none;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		font-weight: bold;
	
		text {
			width: 100%;
		}
	}
	
	.namebox:nth-last-child(1) {
		border-right: 1px solid rgb(0, 128, 0);
	}
	
	.value {
		height: 100%;
		width: 35%;
		padding: 5px;
		text-align: center;
		border: 1px solid rgb(0, 128, 0);
		word-wrap: break-word;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: none;
		font-size: 16px;
	
		text {
			width: 100%;
	
		}
	}
	
	.value:nth-child(2) {
		border-right: none;
	}
	
	.line:nth-last-child(1) {
		border-bottom: 1px solid rgb(0, 128, 0);
	}
	
	.autoheight {
		min-height: 60px;
		height: auto;
		border: 1px solid rgb(0, 128, 0);
	
		.namebox {
			width: 25%;
			border: none;
		}
	
		.value {
			min-height: 60px;
			width: 75%;
			border: none;
			border-left: 1px solid rgb(0, 128, 0);
		}
	}
	
	.canlclefb {
		font-weight: 400;
	}
	
	::v-deep .el-input__inner {
		font-size: 16px;
		color: rgb(0, 128, 0) !important;
	}
	
	.erjititle {
		font-size: 16px !important;
	}
	.mytableinner{
		color: rgb(0, 128, 0);
	}
	.lmtlist:nth-last-child(1){
		border-bottom: 1px solid rgb(0,128,0) !important;
	}
</style>